import React from "react";
import { graphql } from "gatsby";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";

import {
  WaveFooter,
  Section,
  Content,
  FeatureDuo,
  EmailContainer,
  Headline,
  ContactSubline,
  Text,
  Visualization,
} from "../components/SubComponents";

import ContactSVG from "../assets/images/svg/contactsarah.svg";

import { EMAIL_SARAH, EMAIL_ANNA, EMAIL_KERRIE } from "../constants/linkdata";

const ContactPage = ({ location: { pathname } }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const agencyEmail = {
    user: language === "en" ? EMAIL_ANNA.USER : EMAIL_KERRIE.USER,
    mail: language === "en" ? EMAIL_ANNA.DOMAIN : EMAIL_KERRIE.DOMAIN,
  };

  return (
    <BaseLayout>
      <SEO title={t("Contact")} path={pathname} />
      <Section id={"contact"}>
        <Content>
          <FeatureDuo spaceBottom verticalOnly>
            <Text verticalOnly>
              <Headline>
                <Trans>Let's chat!</Trans>
              </Headline>
              <ContactSubline>
                {/* prettier-ignore */}
                <Trans>
                  To talk about your project or request a custom demo pop me an email at <EmailContainer userString={EMAIL_SARAH.USER} mailString={EMAIL_SARAH.DOMAIN}></EmailContainer>
                </Trans>
              </ContactSubline>
              <ContactSubline>
                {/* prettier-ignore */}
                <Trans>
                  Or if you'd rather discuss details with an agent, mail Anna at Voice Squad: <EmailContainer userString={agencyEmail.user} mailString={agencyEmail.mail}></EmailContainer>
                </Trans>
              </ContactSubline>
            </Text>
            <Visualization>
              <ContactSVG />
            </Visualization>
          </FeatureDuo>
        </Content>
        <WaveFooter />
      </Section>
    </BaseLayout>
  );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "contact"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
